// src/config/axiosInstance.js
import axios from 'axios';
import config from './config';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: config.API_BASEURL, // Use the common API URL
});

// Add a request interceptor
axiosInstance.interceptors.request.use((request) => {
  request.metadata = { startTime: new Date() }; // Store the start time
  return request;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const endTime = new Date();
    const duration = endTime - response.config.metadata.startTime; // Calculate the duration
    console.log(`API Call: ${response.config.url} | Duration: ${duration}ms`); // Log duration
    return response;
  },
  (error) => {
    const endTime = new Date();
    const duration = endTime - error.config.metadata.startTime; // Calculate duration
    console.error(`API Call: ${error.config.url} | Duration: ${duration}ms | Error: ${error.message}`);
    return Promise.reject(error);
  }
);

export default axiosInstance;

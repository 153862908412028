import React from 'react';
import {
  PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font
} from '@react-pdf/renderer';

// Add a custom font if needed
// Font.register({ family: 'Roboto', src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxP.ttf' });

// Define styles for the PDF document
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    backgroundColor: '#FFFFFF',
  },
  header: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: 'center',
    color: '#333',
  },
  logo: {
    width: 100,
    height: 40,
    marginBottom: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    fontSize: 12,
    textAlign: 'left',
  },
  footer: {
    fontSize: 10,
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'gray',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'gray',
  },
});

// Define the PDF content as a React component
const ClientPDFDocument = ({ client, logoUrl }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Logo and Header */}
      <View style={{ alignItems: 'center' }}>
        <Image style={styles.logo} src={logoUrl} />
        <Text style={styles.header}>Client Report</Text>
      </View>
      
      {/* Client details section */}
      <View style={styles.section}>
        <Text>Client Name: {client.client_name}</Text>
        <Text>Email: {client.email}</Text>
        <Text>Mobile No: {client.mobile_no}</Text>
        <Text>Address: {client.address}</Text>
        <Text>Client ID: {client.client_id}</Text>
        <Text>Client Secret: {client.client_secret}</Text>
        <Text>Client Token: {client.client_token ? client.client_token : 'Token not generated'}</Text>
      </View>

      {/* Footer and Page Number */}
      <Text style={styles.footer}>Generated by Company Name - Confidential</Text>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `Page ${pageNumber} of ${totalPages}`
      )} />
    </Page>
  </Document>
);

// Button to trigger PDF download
const ExportClientPDF = ({ client }) => {
  const logoUrl = 'https://your-company-logo-url.com/logo.png'; // Replace with your logo URL

  return (
    <PDFDownloadLink
      document={<ClientPDFDocument client={client} logoUrl={logoUrl} />}
      fileName={`${client.client_name}-report.pdf`}
    >
      {({ loading }) => (loading ? 'Generating PDF...' : 'Download PDF')}
    </PDFDownloadLink>
  );
};

export default ExportClientPDF;

// src/config/config.js

const config = {
   // Base URL from environment variable or fallback to default
   API_BASEURL: process.env.REACT_APP_API_BASEURL || 'https://dev.creditpredict.in/api/v1',
   
   API_LOGIN_URL: process.env.REACT_APP_API_LOGIN_URL || '/auth/signin',
   SIGNUP_URL: process.env.REACT_APP_SIGNUP_URL || '/auth/signup',
   
   HOME_URL: process.env.REACT_APP_HOME_URL || '/home',
   API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_URL || '/clients',
   API_USERS_URL: process.env.REACT_APP_API_USERS_URL || '/users',
   API_ROLE_URL: process.env.REACT_APP_API_ROLE_URL || '/role',
   
   scope: process.env.REACT_APP_SCOPE || 'crdprd',
   grant_type: process.env.REACT_APP_GRANT_TYPE || 'client_credentials',
   auth_token_url: process.env.REACT_APP_AUTH_TOKEN_URL || '/Oauth/token',
   
   API_KEYS_URL: process.env.REACT_APP_API_KEYS_URL || '/api_keys',
   API_SAVE_URL: process.env.REACT_APP_API_SAVE_URL || '/save_selection',
   
   API_ROUTE_URL: process.env.REACT_APP_API_ROUTE_URL || "https://dev.creditpredict.in/api/v1/apiheader",
   API_DETAIL_URL: process.env.REACT_APP_API_DETAIL_URL || "https://dev.creditpredict.in/api/v1/api-details",
   API_DETAIL_GET_URL: process.env.REACT_APP_API_DETAIL_GET_URL || "https://dev.creditpredict.in/api/v1/api-get-details",
   API_DETAIL_POST_URL: process.env.REACT_APP_API_DETAIL_POST_URL || "https://dev.creditpredict.in/api/v1/api-post-details",
   DISTRICT_API_URL: process.env.REACT_APP_DISTRICT_API_URL || "/distData",
   API_CLIENT_AUDIT_URL: process.env.REACT_APP_API_CLIENT_AUDIT_URL || "/auditSummary",
   
 };
 
 export default config;
 
import React, { useState } from 'react';
import axiosInstance from './config/axiosInstance'; // Use the configured Axios instance
import config from './config/config';

import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate(); // For navigation

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
    return passwordRegex.test(password);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };  

 const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

 // Email validation
 if (!validateEmail(email)) {
  setError('Please enter a valid email address.');
  setLoading(false);
  return;
}

    // Password validation
    if (!validatePassword(password)) {
      setError('Password must be 8-15 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.');
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.post(`${config.SIGNUP_URL}`, {
        username,
        email, // Include email in the request body
        password,
      });

      // Assuming the response contains a success indicator
      if (response.data.success) {
        setSuccess('Signup successful!'); // Set success message
        // Optionally, you can log the user in here if needed
        // login(response.data.user); // Uncomment if you have a login function
       // navigate(config.HOME_URL); // Redirect to home page
        navigate(`${config.HOME_URL}`); // Redirect to home page
      } else {
        // Failed signup
        setError(response.data.message); // Set error message
      }
    } catch (err) {
      setError('An error occurred. Please try again.'); // Set a general error message
      console.error(err); // Log the error for debugging
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="mt-5">
    <div className="row justify-content-center">
      <div className="col-md-4">
        <div className="card shadow p-4">
   

      <h2 className="text-center">Signup</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            name="username"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            maxLength={15}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading ? 'Signing up...' : 'Signup'}
              </button>
      </form>

    
    </div>
    </div>
    </div>
    </div>
  );
};

export default Signup;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import logo from './assets/images/logo.png';
import './assets/css/navCustom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar as BootstrapNavbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { FaHome, FaUser, FaSignOutAlt, FaCog, FaUsers } from 'react-icons/fa'; // Import icons

const CustomNavbar = () => {
  const { isAuthenticated, logout, sessionData,remainingTime, formatTime } = useAuth();
  const [activeMenu, setActiveMenu] = useState(null);

  return (
    <BootstrapNavbar bg="light" expand="lg" className="shadow-sm">
      <BootstrapNavbar.Brand as={Link} to="/">
        <img src={logo} alt="Logo" width="300" height="30" className="d-inline-block align-text-top" />
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          {isAuthenticated && sessionData ? (
            <>
              <Nav.Link 
                as={Link} 
                to="/" 
                active={activeMenu === 'home'} 
                onMouseEnter={() => setActiveMenu('home')} 
                onMouseLeave={() => setActiveMenu(null)}
                aria-label="Home"
              >
                <FaHome className="me-2" /> Home
              </Nav.Link>

              <NavDropdown 
                title="Clients Process" 
                id="client-dropdown" 
                active={activeMenu === 'client'} 
                onMouseEnter={() => setActiveMenu('client')} 
                onMouseLeave={() => setActiveMenu(null)}
                aria-label="Clients Process"
              >
                <NavDropdown.Item as={Link} to="/client/creation">
                  <FaUsers className="me-2" /> Client Details
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/client/token">
                  <FaCog className="me-2" /> Client Token Generation
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/user/privilege">
                  <FaUser className="me-2" /> Client Privilege Mapping
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/client/audit">
                  <FaUser className="me-2" /> Client Audit summary
                </NavDropdown.Item> 
                <NavDropdown.Item as={Link} to="/user/ExcelData">
                  <FaUser className="me-2" /> Upload API Data
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown 
                title="Admin Process" 
                id="admin-dropdown" 
                active={activeMenu === 'user'} 
                onMouseEnter={() => setActiveMenu('user')} 
                onMouseLeave={() => setActiveMenu(null)}
                aria-label="Admin Process"
              >
                <NavDropdown.Item as={Link} to="/user/Role">
                  <FaCog className="me-2" /> Role
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/user/ApiUser">
                  <FaUsers className="me-2" /> User Role Mapping
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/user/apiData">
                  <FaUsers className="me-2" /> API Master
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/user/RoleApi">
                  <FaCog className="me-2" /> Role API Mapping
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={<FaUser />} id="user-dropdown" className="ms-auto"  drop="start">
                <NavDropdown.Item disabled>Welcome {sessionData.user?.username}</NavDropdown.Item>
                <NavDropdown.Item disabled>
                  <FaCog className="me-2" /> Session Time Left: {formatTime(remainingTime)}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/signup">
                  <FaUser className="me-2" /> Signup
                </NavDropdown.Item>
                <NavDropdown.Item as={Button} variant="link" onClick={logout}>
                  <FaSignOutAlt className="me-2" /> Logout
                </NavDropdown.Item>
              </NavDropdown>
            </>
          ) : (
            <Nav.Link as={Link} to="/login" aria-label="Login">
              <FaUser className="me-2" /> Login
            </Nav.Link>
          )}
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default CustomNavbar;

import React from 'react';
import { BrowserRouter as Router, Route, Routes,useRoutes,Navigate  } from 'react-router-dom';
import './assets/css/nav.css'; // Add your custom CSS
import './assets/css/styles.css'; // Add your custom CSS
import { useAuth } from './AuthContext'; // Import the Auth context
import RoutesConfig from './routes'; // Import the routes
import Footer from './Footer';
import CustomNavbar from './Navbar';


// Main App component
const App = () => {
  const routing = useRoutes(RoutesConfig()); // Use the routes

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <CustomNavbar />
        <div className="container mt-4" style={{ flex: '1', paddingBottom: '50px' }}>
        {routing}
        </div>
        <Footer/>
       </div>
    );
};




export default App;

import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Switch,
  Table,
  Card,
  Space,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  FileAddOutlined,
  FormOutlined,
  SearchOutlined,
  EyeOutlined,
  CopyOutlined,
  FilePdfOutlined,
  CloseOutlined,
} from "@ant-design/icons"; // Import necessary icons
import Column from "antd/es/table/Column";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "./config/config";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const { confirm } = Modal;

const ApiMaster = () => {
  const [data, setData] = useState([]);
  const [dataDtl, setDataDtl] = useState(null);
  const [dataDtlRows, setDataDtlRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const buttonStyle = {
    backgroundColor: "#1890ff",
    borderColor: "#1890ff",
    transition: "transform 0.3s ease", // Add a smooth transition
  };

  const onHoverStyle = {
    transform: "scale(1.1)", // Slightly enlarge on hover
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.API_ROUTE_URL}`);
      setData(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (values) => {
    const method = editRecord ? "PUT" : "POST";
    const url = editRecord
      ? `${config.API_ROUTE_URL}/${editRecord.api_id}`
      : `${config.API_ROUTE_URL}`;

    try {
      await axios({
        method,
        url,
        headers: { "Content-Type": "application/json" },
        data: values,
      });
      message.success(
        editRecord ? "API updated successfully!" : "API added successfully!"
      );
      setIsModalOpen(false);
      form.resetFields();
      fetchData();
    } catch (error) {
      message.error(`Something went wrong: ${error.message}`);
    }
  };

  const openModal = (record = null) => {
    setEditRecord(record);
    setIsModalOpen(true);
    if (record) {
      form.setFieldsValue(record);
    } else {
      form.resetFields();
    }
  };

  const fetchDetails = async (api_id) => {
    try {
      const response = await axios.post(
        `${config.API_DETAIL_GET_URL}`,
        { api_id } // Send the api_id in the body
      );

      setDataDtl(response.data);
      setDataDtlRows(response.data);
      setIsDetailsModalOpen(true); // Open the details modal
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const handleAddRow = () => {
    setDataDtlRows((prev) => [
      ...prev,
      { display_column: "", json_column: "", status: true },
    ]);
  };

  const handleDeleteRow = (index) => {
    setDataDtlRows((prev) => {
      const updatedRows = [...prev];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
    message.success("Empty Row removed!");
  };

  const handleToggleActive = (index) => {
    const updatedRows = [...dataDtlRows];
    updatedRows[index].status = !updatedRows[index].status;
    setDataDtlRows(updatedRows);
  };

  const handleSave = async (api_id) => {
    if (!api_id) {
      console.log("api_id is null");
    }

    const filteredRows = dataDtlRows.filter(
      (row) =>
        (row.display_column && row.display_column.trim() !== "") ||
        (row.json_column && row.json_column.trim() !== "")
    );
    if (filteredRows.length === 0) {
      message.warning("Please Enter the Display Column & json Column");
      return;
    }

    try {
      await axios.post(`${config.API_DETAIL_POST_URL}`, {
        api_id,
        details: filteredRows,
      });
      message.success("API details saved successfully!");
      fetchDetails(api_id);
    } catch (error) {
      message.error(`Failed to save API details: ${error.message}`);
    }
  };

  const columns = [
    {
      title: "API ID",
      dataIndex: "api_id",
      key: "api_id",
      sorter: (a, b) => a.api_id - b.api_id,
    },
    {
      title: "API Name",
      dataIndex: "api_name",
      key: "api_name",
      ellipsis: true,
      sorter: (a, b) => (a.api_name || "").localeCompare(b.api_name || ""),
    },
    {
      title: "API Route",
      dataIndex: "api_route",
      key: "api_route",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record, index) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              type="primary"
              shape="circle"
              icon={
                <FormOutlined style={{ fontSize: "18px", color: "#fff" }} />
              }
              onClick={() => openModal(record)}
              style={{ backgroundColor: "#1890ff", borderColor: "#1890ff" }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              type="primary"
              shape="circle"
              icon={
                <DeleteOutlined style={{ fontSize: "18px", color: "#fff" }} />
              }
              onClick={() => handleHeaderToggleActive(index)}
              style={{
                backgroundColor: record.status ? "red" : "green",
                borderColor: "#ff4d4f",
                marginRight: "20px",
              }}
            />
          </Tooltip>

          <Button
            type="primary"
            icon={<PlusOutlined style={{ fontSize: "18px", color: "#fff" }} />}
            onClick={() => {
              fetchDetails(record.api_id);
              setDataDtlRows([]);
            }}
            style={{
              backgroundColor: "#1890ff",
              borderColor: "#1890ff",
              marginRight: "20px",
            }}
          >
            Add Key Details{" "}
          </Button>
        </Space>
      ),
    },
  ];
  const handleHeaderToggleActive = (index) => {
    if (index >= 0 && index < data.length) {
      const updatedRows = [...data];
      const apiId = updatedRows[index].api_id;
      const isCurrentlyActive = updatedRows[index].status;

      confirm({
        title: `Are you sure you want to delete this Api_id : ${apiId}`,
        okText: "yes",
        okType: "primary",
        cancelText: "Cancel",
        onOk: () => {
          updatedRows[index].status = !isCurrentlyActive;
          setData(updatedRows);
          axios
            .put(`${config.API_ROUTE_URL}/${apiId}`, {
              status: updatedRows[index].status,
            })
            .then(() => {
              message.success(
                `API ${
                  isCurrentlyActive ? "deactivated" : "activated"
                } successfully!`
              );
              fetchData();
            })
            .catch((error) => {
              message.error(`Error updating API status: ${error.message}`);
              updatedRows[index].status = isCurrentlyActive;
              setData(updatedRows);
            });
        },
        onCancel() {
          console.log("Canceled the action");
        },
      });
    } else {
      console.error("Invalid index:", index);
    }
  };

  if (loading) return <Spin tip="Loading API data..." />;
  if (error)
    return <Alert message="Error" description={error} type="error" showIcon />;

  return (
    <div>
      <Card className="grid-card">
        <div style={{ padding: "20px" }}>
          <h2
            style={{
              textAlign: "center",
              marginBottom: "20px",
              color: "#1890ff",
            }}
          >
            API Master
          </h2>

          <Tooltip title="Add">
            <Button
              type="primary"
              shape="circle"
              icon={
                <PlusOutlined style={{ fontSize: "18px", color: "#faf" }} />
              }
              onClick={() => openModal()}
              style={buttonStyle}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = onHoverStyle.transform)
              }
              onMouseLeave={(e) => (e.currentTarget.style.transform = "none")} // Revert back on mouse leave
            />
          </Tooltip>

          <Table
            dataSource={data}
            columns={columns}
            className="grid-table"
            rowKey="api_id"
            size="small"
            loading={loading} // Add loader for the table
            pagination={{
              ...pagination,
              pageSizeOptions: ["5", "10", "20", "30"], // Options for number of items per page
              showSizeChanger: true, // Show size changer
              onShowSizeChange: (current, size) =>
                setPagination({ current, pageSize: size }), // Handle size change
              onChange: (page, pageSize) =>
                setPagination({ current: page, pageSize }), // Handle page change
            }}
            scroll={{ x: true }} // Enable horizontal scroll
            responsive
            style={{ marginTop: 20 }}
          />

          <Modal
            title={editRecord ? "Edit API" : "Add API"}
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            onOk={() => form.submit()}
            okText={editRecord ? "Update" : "Save"}
          >
            <Form form={form} onFinish={handleFormSubmit} layout="vertical">
              <Form.Item
                label="API Name"
                name="api_name"
                rules={[
                  { required: true, message: "Please input the API name!" },
                ]}
              >
                <Input placeholder="Enter API Name" />
              </Form.Item>
              <Form.Item
                label="API Route"
                name="api_route"
                rules={[
                  { required: true, message: "Please input the API route!" },
                ]}
              >
                <Input placeholder="Enter API Route" />
              </Form.Item>
            </Form>
          </Modal>

          {/* Modal for Details */}
          <Modal
            open={isDetailsModalOpen}
            centered
            onCancel={() => {
              setIsDetailsModalOpen(false);
            }}
            footer={null}
            width={900}
          >
            {dataDtl && (
              <div
                style={{
                  paddingBottom: "80px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h2 style={{ marginBottom: "10px" }}>API Keys Details</h2>
                <p> API ID: {dataDtl[0].api_id}</p>
                {/* <p> API ID: {dataDtl[0].api_name}</p>
                <p> API ID: {dataDtl[0].api_route}</p> */}
                <div style={{ marginLeft: "auto" }}></div>
                <Table
                  dataSource={dataDtlRows}
                  rowKey="api_dtl_id"
                  style={{ marginTop: "20px", width: "100%" }}
                  pagination={false}
                >
                  <Column
                    title="Sub_api_id"
                    dataIndex="api_dtl_id"
                    width={100}
                    render={(text, record, index) => (
                      <Input
                        value={text}
                        disabled={true}
                        onChange={(e) => {
                          const newRows = [...dataDtlRows];
                          newRows[index].api_dtl_id = e.target.value;
                          setDataDtlRows(newRows);
                        }}
                      />
                    )}
                  />

                  <Column
                    title="Display Column"
                    dataIndex="display_column"
                    render={(text, record, index) => (
                      <Input
                        value={text}
                        onChange={(e) => {
                          const newRows = [...dataDtlRows];
                          newRows[index].display_column = e.target.value;
                          setDataDtlRows(newRows);
                        }}
                      />
                    )}
                  />
                  <Column
                    title="JSON Column"
                    dataIndex="json_column"
                    render={(text, record, index) => (
                      <Input
                        value={text}
                        onChange={(e) => {
                          const newRows = [...dataDtlRows];
                          newRows[index].json_column = e.target.value;
                          setDataDtlRows(newRows);
                        }}
                      />
                    )}
                  />
                  {dataDtl[0]?.api_dtl_id && (
                    <Column
                      title={"Active"}
                      dataIndex={"status"}
                      render={(text, record, index) => (
                        <Tooltip title="Delete">
                          <Button
                            type="primary"
                            shape="circle"
                            icon={
                              <DeleteOutlined
                                style={{ fontSize: "18px", color: "#fff" }}
                              />
                            }
                            onClick={() => handleToggleActive(index)}
                            style={{
                              backgroundColor: record.status ? "red" : "gray",
                              marginRight: "20px",
                            }}
                          />
                        </Tooltip>
                      )}
                    />
                  )}
                  <Column
                    render={(text, record, index) =>
                      dataDtlRows.length > 1 &&
                      !record.api_dtl_id &&
                      !record.display_column &&
                      !record.json_column && (
                        <Button
                          color="danger"
                          variant="solid"
                          onClick={() => handleDeleteRow(index)}
                        >
                          Remove row
                        </Button>
                      )
                    }
                  />
                </Table>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <Button type="default" onClick={handleAddRow}>
                      Add Row
                    </Button>
                  </div>

                  <Button
                    type="primary"
                    onClick={() => handleSave(dataDtl[0]?.api_id)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </Card>
    </div>
  );
};
export default ApiMaster;

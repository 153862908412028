
// src/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Assuming you have an AuthContext

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth(); // Check if the user is authenticated

  // If the user is not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // If authenticated, render the child components (protected routes)
  return children;
};

export default ProtectedRoute;

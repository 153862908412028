import { Button, Card, Form, Input, message, Modal, Spin, Table,Space,Tooltip } from 'antd';
import { PlusOutlined,FileAddOutlined, FormOutlined ,SearchOutlined,EditOutlined, EyeOutlined, CopyOutlined, FilePdfOutlined, DeleteOutlined } from '@ant-design/icons'; // Import necessary icons
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../src/config/config';


const RoleManagement = () => {
    const [roles, setRoles] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRole, setEditingRole] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    
    const buttonStyle = {
        backgroundColor: '#1890ff', 
        borderColor: '#1890ff',
        transition: 'transform 0.3s ease', // Add a smooth transition
    };
    
    const onHoverStyle = {
        transform: 'scale(1.1)', // Slightly enlarge on hover
    };

    const [form] = Form.useForm();

    useEffect(() => {
        const fetchRoles = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${config.API_BASEURL}/role`);
                const roleData = response.data.map(role => ({
                    key: role.role_id,
                    id: role.role_id,
                    name: role.role_desc,
                }));
                setRoles(roleData);
            } catch (error) {
                message.error('Failed to load roles');
            }
            finally {
                setLoading(false);
            }
        };
        fetchRoles(); 
    }, []); 

    const showModal = (role = null) => {
        setEditingRole(role);
        if (role) {
            form.setFieldsValue({
                name: role.name,
            });
        } else {
            form.resetFields();
        }
        setIsModalVisible(true);
    };

    const handleSubmit = async (values) => {
        const roleName = values.name;
 
        if (!editingRole) {
            const existingRole = roles.find(role => role.name === roleName);
            if (existingRole) {
                message.error('This role name already exists.');
                return;
            }
        }

        try {
            setLoading(true);
            const response = await axios.post(`${config.API_BASEURL}/role/post`, {
                role_desc: roleName,
                role_id: editingRole ? editingRole.id : undefined 
            });

            if (editingRole) {
                const updatedRoles = roles.map(role =>
                    role.key === editingRole.key ? { ...role, name: roleName } : role
                );
                setRoles(updatedRoles);
                message.success('Role updated successfully');
            } else {
                const newRole = {
                    key: response.data.role_id,
                    id: response.data.role_id,
                    name: roleName,
                };
                setRoles([...roles, newRole]);
                message.success('Role added successfully');
            }
        } catch (error) {
            message.error('Failed to save role');
        }
        finally {
            setLoading(false);
            form.resetFields();
            setIsModalVisible(false);
            setEditingRole(null);
        }
    };

    const handleDeleteRole = async (key) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this role?',
            onOk: async () => {
                try {
                    await axios.post(`${config.API_BASEURL}/role/delete`, { role_id: key } );
                    setRoles(roles.filter(role => role.key !== key));
                    message.success('Role deleted successfully');
                } catch (error) {
                    message.error('Failed to delete role');
                }
            },
        });
    };

   
    const columns = [
        {
            title: 'Role ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Role Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                
                <Space size="middle">

<Tooltip title="Edit">
<Button 
                       type="primary" 
                       shape="circle" 
                       icon={<FormOutlined style={{ fontSize: '18px', color: '#fff' }} />} 
                       onClick={() => showModal(record)} 
                       style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', marginRight: "20px" }}
                   />


               </Tooltip>
               <Tooltip title="Delete">
<Button 
                       type="primary" 
                       shape="circle" 
                       icon={<DeleteOutlined style={{ fontSize: '18px', color: '#fff' }} />} 
                       onClick={() => handleDeleteRole(record.key)}
                       style={{ backgroundColor: '#ff4d4f', borderColor: '#ff4d4f', marginRight: "20px" }}
                   />


               </Tooltip>              
        
         
        
        </Space>




            ),
        },
    ];

     //const paginatedRoles = roles.slice((currentPage - 1) * pageSize, currentPage * pageSize); // Pagination logic

    return (
        <>
            <Card className="grid-card">
                <div>
                    <h4 style={{ gap: '20px', padding: '20px', textAlign: 'center', color:'#006dff' }}>Role</h4>
                    <Tooltip title="Add">
                <Button 
    type="primary" 
    shape="circle" 
    icon={<PlusOutlined style={{ fontSize: '18px', color: '#faf' }} />} 
    onClick={() => showModal()} 
    style={buttonStyle}
    onMouseEnter={e => e.currentTarget.style.transform = onHoverStyle.transform}
    onMouseLeave={e => e.currentTarget.style.transform = 'none'} // Revert back on mouse leave
/></Tooltip>
                         
                    {loading ? 
                    <Spin style={{ marginTop: "20px" }} /> : (
                        <>
                            <Table 
                                columns={columns} 
                                style={{ marginTop: "20px" }} 
                                loading={loading}
                                dataSource={roles} 
                                rowKey="key"
                                className="grid-table" 
                               
                                pagination={{
                                    ...pagination,
                                    pageSizeOptions: ['5', '10', '20', '30'], // Options for number of items per page
                                    showSizeChanger: true, // Show size changer
                                    onShowSizeChange: (current, size) => setPagination({ current, pageSize: size }), // Handle size change
                                    onChange: (page, pageSize) => setPagination({ current: page, pageSize }), // Handle page change
                                }}
                                scroll={{ x: true }} // Enable horizontal scroll
                                responsive
                            />
                            
                        </>
                    )}
                    <Modal
                        title={editingRole ? "Edit Role" : "Add New Role"}
                        visible={isModalVisible}
                        onCancel={() => setIsModalVisible(false)}
                        footer={null}
                    >
                        <Form form={form} onFinish={handleSubmit}>
                            <Form.Item
                                name="name"
                                label="Role Name"
                                rules={[{ required: true, message: 'Please enter a role name!' }]}
                            >
                                <Input placeholder="Enter role name" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    {editingRole ? 'Update' : 'Save'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Card>
        </>
    );
};

export default RoleManagement;

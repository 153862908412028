import { UploadOutlined } from '@ant-design/icons';
import { Button, Radio, Select, Table, Upload, message,Card } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import config from '../src/config/config';








function DataUploader() {
  const [filesData, setFilesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSheet, setSelectedSheet] = useState(null); 
  const [actionType, setActionType] = useState('save');
  const [uploadType, setUploadType] = useState('district'); // Select between "district" and "crop"
  const [isDataSaved, setIsDataSaved] = useState(false);

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetsData = workbook.SheetNames.map((sheetName) => {
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: '' });
        
        const limitedData = worksheet.map((row, index) => ({ ...row, key: index }));
        
        return {
          sheetName,
          columns: limitedData.length > 0 ? Object.keys(limitedData[0]).map((key) => ({
            title: key,
            dataIndex: key,
            key,
          })) : [],
          data: limitedData,
        };
      });

      const existingFileIndex = filesData.findIndex(f => f.fileName === file.name);
      
      if (existingFileIndex >= 0) {
        const updatedFilesData = [...filesData];
        updatedFilesData[existingFileIndex].sheetsData = sheetsData;
        setFilesData(updatedFilesData);
      } else {
        setFilesData((prevFilesData) => [...prevFilesData, { fileName: file.name, sheetsData }]);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const beforeUpload = (file) => {
    const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      message.error(`${file.name} is not an .xlsx file`);
    }
    return isExcel || Upload.LIST_IGNORE;
  };

  const uploadProps = {
    name: 'file',
    beforeUpload,
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        handleFile(file);
        onSuccess('ok');
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file Fetched successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file Fetched failed.`);
      }
    },
    accept: ".xls,.xlsx",
  };

  const postDataToApi = async () => {
    if (isDataSaved) {
        message.warning('Data already exists.');
        return;
    }
    setLoading(true);
    try {
        const dataToPost = filesData.flatMap(file =>
            file.sheetsData
                .filter(sheet => sheet.sheetName === selectedSheet)
                .map(sheet => ({ datas: sheet.data }))
        );

        const endpoint = uploadType === 'district' ? `${config.API_BASEURL}/district_api/post` : `${config.API_BASEURL}/crop_api/post`;
        const response = await axios.post(endpoint, dataToPost);

        if (response.status === 201) {
            message.success('Data inserted into the database successfully!');
            setIsDataSaved(true);
        } else {
            message.error('Failed to insert data into the database.');
        }
    } catch (error) {
        if (error.response && error.response.status === 409) {
            message.warning('Data already exists.');
            setIsDataSaved(true);
        } else {
            message.error('An error occurred while inserting data.');
        }
    } finally {
        setLoading(false);
    }
  };

  const overrideDataInApi = async () => {
    setLoading(true);
    try {
      const dataToOverride = filesData.flatMap(file =>
        file.sheetsData
          .filter(sheet => sheet.sheetName === selectedSheet)
          .map(sheet => ({ datas: sheet.data }))
      );

      const endpoint = uploadType === 'district' ? `${config.API_BASEURL}/district_api/overrideData` : `${config.API_BASEURL}/crop_override/post`;
      const response = await axios.post(endpoint, dataToOverride);

      if (response.status === 201) {
        message.success('Data overridden successfully!');
      } else {
        message.error('Failed to override data in the database.');
      }
    } catch (error) {
      message.error('An error occurred while overriding data in the API.');
    } finally {
      setLoading(false);
    }
  };

  const handleAction = () => {
    if (actionType === 'save') {
      postDataToApi();
    } else if (actionType === 'override') {
      overrideDataInApi(); 
    }
  };

  const renderFilesData = () => {
    return filesData.map((file, fileIndex) => (
      <div key={fileIndex} style={{ marginTop: '20px' }}>
        <h3>{file.fileName}</h3>
        
        <Select
          placeholder="Select a sheet"
          style={{ width: 200, marginBottom: 20 }}
          onChange={setSelectedSheet}
          value={selectedSheet}
        >
          {file.sheetsData.map((sheet, sheetIndex) => (
            <Select.Option key={sheetIndex} value={sheet.sheetName}>
              {sheet.sheetName}
            </Select.Option>
          ))}
        </Select>

        {selectedSheet && file.sheetsData.map((sheet, sheetIndex) => {
          if (sheet.sheetName === selectedSheet) {
            return (
              <div key={sheetIndex} style={{ marginBottom: '20px' }}>
                <h4>Sheet: {sheet.sheetName}</h4>
                <Table
                  columns={sheet.columns.map((column) => ({
                    ...column,
                    width: column.width || 150,
                    
                  }))}
                  className="grid-table"
                  dataSource={sheet.data}
                  pagination={true}
                  scroll={{ x: 'max-content', y: 400 }}
                />
              </div>
            );
          }
          return null; 
        })}
      </div>
    ));
  };

  return (
    <Card className="grid-card">
    <div className="App">
      <h2 style={{'color': 'rgb(0, 106, 255)',textAlign:'center'}}>Upload API Data</h2>
      <Upload {...uploadProps}>
        <div className='d-flex justifycontent-center gap-3'>
          <p style={{fontSize:'20px',color:'black',fontFamily:'sans-serif'}}>File :</p>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </div>
      </Upload>
      
      {filesData.length > 0 && (
        <>
          <Radio.Group
            onChange={(e) => setUploadType(e.target.value)}
            value={uploadType}
            style={{ marginTop: '20px' }}
            
          >
            <Radio value="district">District</Radio>
            <Radio value="crop">Crop</Radio>
          </Radio.Group>

          <Radio.Group
            onChange={(e) => setActionType(e.target.value)}
            value={actionType}
            style={{ marginTop: '20px', marginLeft: '10px' }}
          >
            <Radio value="save">Save</Radio>
            <Radio value="override">Override</Radio>
          </Radio.Group>

          <Button
            type="primary"
            onClick={handleAction}
            loading={loading}
            style={{ marginTop: '20px', marginLeft: '10px' }}
          >
            {actionType === 'save' ? 'Save' : 'Override'}
          </Button>
        </>
      )}

      {renderFilesData()}
    </div>
    </Card>
  );
}

export default DataUploader;

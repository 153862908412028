import React from 'react';

const Home = () => {
  return (
    <div>
      
      <div className="container mt-5">
        <h1>Welcome to Home Page</h1>
        <p>Explore our services and manage your account easily.</p>
      </div>
    </div>
  );
};

export default Home;

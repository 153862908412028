import React, { useState, useEffect } from "react";
import { Table, Form, Select, Button, Modal, message, Card, Space,Tooltip } from "antd";
import { PlusOutlined,FileAddOutlined, FormOutlined ,SearchOutlined,EditOutlined, EyeOutlined, CopyOutlined, FilePdfOutlined, DeleteOutlined } from '@ant-design/icons'; // Import necessary icons
import axios from "axios";
import config from "../src/config/config";

const { confirm } = Modal;

const RoleAPI = () => {
  const [roles, setRoles] = useState([]);
  const [apis, setApis] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingMapping, setEditingMapping] = useState(null);

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [form] = Form.useForm();

  const buttonStyle = {
    backgroundColor: '#1890ff', 
    borderColor: '#1890ff',
    transition: 'transform 0.3s ease', // Add a smooth transition
};

const onHoverStyle = {
    transform: 'scale(1.1)', // Slightly enlarge on hover
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [rolesResponse, apisResponse, mappingsResponse] =
          await Promise.all([
            axios.get(`${config.API_BASEURL}/role`),
            axios.get(`${config.API_BASEURL}/adm_api_hdr`),
            axios.get(`${config.API_BASEURL}/role_api_mappings`),
          ]);

        setRoles(rolesResponse.data);
        setApis(apisResponse.data);
        const mappingData = mappingsResponse.data.map((mapping) => ({
          key: mapping.api_map_id,
          role_id: mapping.role_id,
          api_id: mapping.api_id,
        }));
        setMappings(mappingData);
      } catch (error) {
        message.error("Failed to load data");
      }
    };

    fetchData();
  }, []);

  const showModal = (mapping = null) => {
    setEditingMapping(mapping);
    if (mapping) {
      form.setFieldsValue({
        role_id: mapping.role_id,
        api_id: mapping.api_id,
      });
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleSubmit = async (values) => {
    try {
      const { role_id, api_id } = values;

      const payload = editingMapping
        ? {
            id: editingMapping.key, // ID for editing
            role_id,
            api_id,
          }
        : {
            role_id,
            api_id,
          };

      // Use a single POST request for both adding and editing
      await axios.post(`${config.API_BASEURL}/role_api_mapping/post`, payload);
      message.success(
        editingMapping
          ? "Mapping updated successfully"
          : "Mapping added successfully"
      );

      // Refresh the mappings
      const response = await axios.get(
        `${config.API_BASEURL}/role_api_mappings`
      );
      const mappingData = response.data.map((mapping) => ({
        key: mapping.api_map_id,
        role_id: mapping.role_id,
        api_id: mapping.api_id,
      }));
      setMappings(mappingData);
    } catch (error) {
      if (error.status === 400) {
        message.warning("This API is already mapped to the role.");
      } else {
        message.error(
          "Failed to save mapping,same role cannot Assign to existing API"
        );
      }
    } finally {
      form.resetFields();
      setIsModalVisible(false);
      setEditingMapping(null);
    }
  };

  

  const handleDeleteMapping = (key) => {
    confirm({
      title: "Are you sure you want to delete this mapping?",
      /*content: "This action cannot be undone.",
      okText: "Yes, delete it",
      okType: "danger",
      cancelText: "Cancel",
     */ onOk: async () => {
        try {

          await axios.post(`${config.API_BASEURL}/role_api_mapping/delete`, {
            id: key,
          });

          setMappings(mappings.filter((mapping) => mapping.key !== key));
          message.success("Mapping deleted successfully");
        } catch (error) {
          message.error("Failed to delete mapping");
        }
      },
      onCancel() {
        message.info("Deletion canceled");
      },
    });
  };

  const columns = [
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",

      render: (text) => {
        const role = roles.find((role) => role.role_id === text);
        return role ? role.role_desc : text;
      },
      sorter:(a,b)=> a.role_id - b.role_id,
    },
    {
      title: "API",
      dataIndex: "api_id",
      key: "api_id",
      render: (text) => {
        const api = apis.find((api) => api.api_id === text);
        return api ? api.api_name : text;
      },
      sorter:(a,b)=> a.api_id - b.api_id,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
       
        <Space size="middle">

<Tooltip title="Edit">
<Button 
                       type="primary" 
                       shape="circle" 
                       icon={<FormOutlined style={{ fontSize: '18px', color: '#fff' }} />} 
                       onClick={() => showModal(record)} 
                       style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', marginRight: "20px" }}
                   />


               </Tooltip>
               <Tooltip title="Delete">
<Button 
                       type="primary" 
                       shape="circle" 
                       icon={<DeleteOutlined style={{ fontSize: '18px', color: '#fff' }} />} 
                       onClick={() => handleDeleteMapping(record.key)}
                       style={{ backgroundColor: '#ff4d4f', borderColor: '#ff4d4f', marginRight: "20px" }}
                   />


               </Tooltip>              
        
         
        
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card className="grid-card">
        <div>
          <h4
            style={{ padding: "20px", textAlign: "center", color: "#1890ff" }}
          >
            Role-API Mapping
          </h4>
         

          <Tooltip title="Add">
                <Button 
    type="primary" 
    shape="circle" 
    icon={<PlusOutlined style={{ fontSize: '18px', color: '#faf' }} />} 
    onClick={() => showModal()} 
    style={buttonStyle}
    onMouseEnter={e => e.currentTarget.style.transform = onHoverStyle.transform}
    onMouseLeave={e => e.currentTarget.style.transform = 'none'} // Revert back on mouse leave
/></Tooltip>

          <Table
            columns={columns}
            dataSource={mappings}
            className="grid-table"
            rowKey="key"
            style={{ marginTop: "20px" }}
            pagination={{
                ...pagination,
                pageSizeOptions: ['5', '10', '20', '30'], // Options for number of items per page
                showSizeChanger: true, // Show size changer
                onShowSizeChange: (current, size) => setPagination({ current, pageSize: size }), // Handle size change
                onChange: (page, pageSize) => setPagination({ current: page, pageSize }), // Handle page change
            }}

          />

          <Modal
            title={editingMapping ? "Edit Mapping" : "Add New Mapping"}
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
          >
            <Form form={form} onFinish={handleSubmit}>
              <Form.Item
                name="role_id"
                label="Role"
                rules={[{ required: true, message: "Please select a role!" }]}
              >
                <Select placeholder="Select a role">
                  {roles.map((role) => (
                    <Select.Option key={role.role_id} value={role.role_id}>
                      {role.role_desc}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="api_id"
                label="API"
                rules={[{ required: true, message: "Please select an API!" }]}
              >
                <Select placeholder="Select an API">
                  {apis.map((api) => (
                    <Select.Option key={api.api_id} value={api.api_id}>
                      {api.api_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {editingMapping ? "Update" : "Save"}
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Card>
    </>
  );
};

export default RoleAPI;

import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Select, message,Card,Space,Tooltip } from 'antd';
import { PlusOutlined,FileAddOutlined, FormOutlined ,SearchOutlined,EditOutlined, EyeOutlined, CopyOutlined, FilePdfOutlined, DeleteOutlined } from '@ant-design/icons'; // Import necessary icons

import axios from 'axios';
import config from '../src/config/config';

const { Option } = Select;

const { confirm } = Modal;

const UserMapping = () => {
    const [userMappings, setUserMappings] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingMapping, setEditingMapping] = useState(null);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [form] = Form.useForm();

    const buttonStyle = {
      backgroundColor: '#1890ff', 
      borderColor: '#1890ff',
      transition: 'transform 0.3s ease', // Add a smooth transition
  };
  
  const onHoverStyle = {
      transform: 'scale(1.1)', // Slightly enlarge on hover
  };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [userResponse, roleResponse, mappingResponse] = await Promise.all([
                    axios.get(`${config.API_BASEURL}/users`),
                    axios.get(`${config.API_BASEURL}/adm_role`),
                    axios.get(`${config.API_BASEURL}/user_mapping`)
                ]);

                // Set users and roles once
                setUsers(userResponse.data);
                setRoles(roleResponse.data);

                // After users and roles are set, map userMappings
                const mappings = mappingResponse.data.map(mapping => ({
                    key: mapping.id,
                    user: userResponse.data.find(user => user.id === mapping.user_id)?.username || 'Unknown',
                    role: roleResponse.data.find(role => role.role_id === mapping.role_id)?.role_desc || 'Unknown',
                    user_id: mapping.user_id,
                    role_id: mapping.role_id
                }));
                setUserMappings(mappings);

            } catch (error) {
                message.error('Failed to load data');
            }
        };

        fetchData(); 
    }, []); 

    const showModal = (mapping = null) => {
        setEditingMapping(mapping);
        if (mapping) {
            // Pre-fill the form fields when editing
            form.setFieldsValue({
                user: mapping.user_id,
                role: mapping.role_id,
            });
        } else {
            form.resetFields();
        }
        setIsModalVisible(true);
    };

   

    const handleSubmit = async (values) => {
        const userId = values.user;  // Capture the user ID
        const roleId = values.role;  // Capture the role ID
    
        // Check if user already has this role (only for creating new mappings)
        if (!editingMapping) {
            const existingMapping = userMappings.find(
                mapping => mapping.user_id === userId && mapping.role_id === roleId
            );
    
            if (existingMapping) {
                // Show error message and prevent the request from going further
                message.error('This user already has the selected role.');
                return;
            }
        }
    
        if (editingMapping) {
            try {
                await axios.put(`${config.API_BASEURL}/user_map/edit`, {
                    id: editingMapping.key,
                    user_id: userId,  
                    role_id: roleId   
                });
    
                // Update the mapping in local state immediately
                const updatedMapping = userMappings.map(mapping =>
                    mapping.key === editingMapping.key
                        ? { ...mapping, user_id: userId, role_id: roleId, user: users.find(user => user.id === userId)?.username, role: roles.find(role => role.role_id === roleId)?.role_desc }
                        : mapping
                );
                setUserMappings(updatedMapping);  // Update the state
    
                message.success('User mapping updated successfully');
            } catch (error) {
                if(error.status === 400){
                    message.warning("This User is already mapped to the role.");
                    }
                    else{
                message.error('Failed to update user mapping,role already present');
            }
        }
    
        } else {
            // Handle Create (No duplicates allowed because of the check above)
            try {
                const response = await axios.post(`${config.API_BASEURL}/user_mapping/post`, values);
    
                const newMapping = {
                    key: response.data.id,  // ID from backend response
                    user: users.find(user => user.id === userId)?.username, // Get user name for display
                    role: roles.find(role => role.role_id === roleId)?.role_desc, // Get role description for display
                    user_id: userId,  // Store user_id for future checks
                    role_id: roleId   // Store role_id for future checks
                };
                setUserMappings([...userMappings, newMapping]);  // Add the new mapping to the state
    
                message.success('User mapping added successfully');
            } catch (error) {
                
                
                message.error('Failed to add user mapping');
                    
            }
        }
    
        // Reset form and close modal
        form.resetFields();
        setIsModalVisible(false);
        setEditingMapping(null);
    };
    

    const handleDeleteMapping = async (key) => {
        confirm({
            title: 'Are you sure you want to delete this user mapping?',
         /*   content: 'This action is irreversible!',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
           */ onOk: async () => {
                try {
                    // Send the 'id' directly in the body
                    await axios.post(`${config.API_BASEURL}/user_map/delete`, { id: key });
                    
                    // Update the UI after deletion
                    setUserMappings(userMappings.filter(mapping => mapping.key !== key));
                    message.success('User mapping deleted successfully');
                } catch (error) {
                    message.error('Failed to delete user mapping');
                }
            },
            onCancel() {
                message.info('Deletion canceled');
            }
        });
    };
    

    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text) => {
                const user = users.find((role) => role.id === text);
                return user ? user.username : text;
              },
            sorter:(a,b)=> a.user.localeCompare(b.user),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (text) => {
                const role = roles.find((role) => role.role_id === text);
                return role ? role.role_desc : text;
              },
            sorter:(a,b)=> a.role.localeCompare(b.role),
            
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                    

       
<Space size="middle">

<Tooltip title="Edit">
<Button 
                       type="primary" 
                       shape="circle" 
                       icon={<FormOutlined style={{ fontSize: '18px', color: '#fff' }} />} 
                       onClick={() => showModal(record)} 
                       style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', marginRight: "20px" }}
                   />


               </Tooltip>
               <Tooltip title="Delete">
<Button 
                       type="primary" 
                       shape="circle" 
                       icon={<DeleteOutlined style={{ fontSize: '18px', color: '#fff' }} />} 
                       onClick={() => handleDeleteMapping(record.key)}
                       style={{ backgroundColor: '#ff4d4f', borderColor: '#ff4d4f', marginRight: "20px" }}
                   />


               </Tooltip>              
        
         
        
        </Space>



            ),
        },
    ];

    return (
      <>
        <Card className="grid-card">
          <div>
            <h4
              style={{
                gap: "20px",
                padding: "20px",
                textAlign: "center",
                color: "#1890ff",
              }}
            >
              User Role Mapping
            </h4>
            

            <Tooltip title="Add">
                <Button 
    type="primary" 
    shape="circle" 
    icon={<PlusOutlined style={{ fontSize: '18px', color: '#faf' }} />} 
    onClick={() => showModal()} 
    style={buttonStyle}
    onMouseEnter={e => e.currentTarget.style.transform = onHoverStyle.transform}
    onMouseLeave={e => e.currentTarget.style.transform = 'none'} // Revert back on mouse leave
/></Tooltip>

            <Table
              columns={columns}
              style={{ marginTop: "20px" }}
              className="grid-table"
              dataSource={userMappings}
              rowKey="key"
              pagination={{
                ...pagination,
                pageSizeOptions: ["5", "10", "20", "30"], // Options for number of items per page
                showSizeChanger: true, // Show size changer
                onShowSizeChange: (current, size) =>
                  setPagination({ current, pageSize: size }), // Handle size change
                onChange: (page, pageSize) =>
                  setPagination({ current: page, pageSize }), // Handle page change
              }}
            />

            <Modal
              title={
                editingMapping ? "Edit User Mapping" : "Add New User Mapping"
              }
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={null}
            >
              <Form form={form} onFinish={handleSubmit}>
                <Form.Item
                  name="user"
                  label="User"
                  rules={[{ required: true, message: "Please select a user!" }]}
                >
                  <Select placeholder="Select a user">
                    {users.map((user) => (
                      <Option key={user.id} value={user.id}>
                        {user.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="role"
                  label="Role"
                  rules={[{ required: true, message: "Please select a role!" }]}
                >
                  <Select placeholder="Select a role">
                    {roles.map((role) => (
                      <Option key={role.role_id} value={role.role_id}>
                        {role.role_desc}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {editingMapping ? "Update" : "Save"}
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Card>
      </>
    );
};

export default UserMapping;


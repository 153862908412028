import React, { createContext, useContext, useState, useEffect } from 'react';

const sessionTime=1800;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [remainingTime, setRemainingTime] = useState(sessionTime); // Default session time (10 minutes = 600 seconds)

  // Function to log in
  const login = (data) => {
    setIsAuthenticated(true);
    setSessionData(data);
    setRemainingTime(sessionTime); // Reset session time to 10 minutes on login
  };

  // Function to log out
  const logout = () => {
    setIsAuthenticated(false);
    setSessionData(null);
    setRemainingTime(0); // Clear session time on logout
  };

  // Countdown timer for session timeout
  useEffect(() => {
    if (isAuthenticated && remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000); // Update every second

      return () => clearInterval(interval);
    } else if (remainingTime === 0) {
      logout(); // Auto logout when session time expires
    }
  }, [isAuthenticated, remainingTime]);

  // Load session data from localStorage on component mount
  useEffect(() => {
    const storedSession = localStorage.getItem('sessionData');
    if (storedSession) {
      setSessionData(JSON.parse(storedSession));
      setIsAuthenticated(true);
    }
  }, []);

  // Save session data to localStorage when it changes
  useEffect(() => {
    if (sessionData) {
      sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
    } else {
      sessionStorage.removeItem('sessionData');
    }
  }, [sessionData]);

  // Function to format time in minutes and seconds
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, sessionData, remainingTime, formatTime }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

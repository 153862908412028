// src/components/AuditSummary.js

import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Card, Tooltip, Spin, Alert, Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { jsPDF } from 'jspdf';
import axiosInstance from './config/axiosInstance';
import config from './config/config';
import 'jspdf-autotable';

const { Search } = Input;

const ClientAuditDetails = () => {
    const [auditSummaries, setAuditSummaries] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedRows, setExpandedRows] = useState({}); // State for tracking expanded rows

    const fetchAuditSummaries = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosInstance.get(`${config.API_CLIENT_AUDIT_URL}`);
            setAuditSummaries(response.data);
            setFilteredData(response.data);
        } catch (err) {
            setError('Failed to fetch audit summaries. Please try again later.');
            console.error('Error fetching audit summaries:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (value) => {
        const filtered = auditSummaries.filter((summary) =>
            summary.clientDetails.client_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filtered);
    };
    const generatePDF = (record) => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [297, 210], // A4 landscape format
        });
    
        // Title
        doc.setFontSize(20);
        doc.text('Audit Summary Details', 20, 20);
    
        // Audit Summary Information
        doc.setFontSize(12);
        doc.text(`Client Name: ${record.clientDetails.client_name || 'N/A'}`, 20, 40);
        doc.text(`API ID: ${record.api_id || 'N/A'}`, 20, 50);
        doc.text(`Hit Count: ${record.hit_count || 0}`, 20, 60);
        doc.text(`Last Hit: ${record.last_hit ? new Date(record.last_hit).toLocaleString() : 'N/A'}`, 20, 70);
        doc.text(`Date: ${record.date || 'N/A'}`, 20, 80);
    
        // New Page for Audit Logs
        doc.addPage();
        doc.text('Audit Detailed Logs', 20, 20);
    
        // Prepare Logs for the Table
        const logs = record.auditLogs.map(log => [
            log.method || 'N/A',
            log.status_code || 'N/A',
            log.url || 'N/A',
            log.request_body || 'N/A',
            log.timestamp ? new Date(log.timestamp).toLocaleString() : 'N/A'
        ]);
    
        // Table Headers
        const headers = [['Method', 'Status Code', 'URL', 'Request Body', 'Timestamp']];
    
        // AutoTable Implementation
        doc.autoTable({
            head: headers,
            body: logs,
            startY: 30,
            theme: 'grid',
            columnStyles: {
                0: { cellWidth: 30 },  // Adjust width for method
                1: { cellWidth: 30 },  // Adjust width for status code
                2: { cellWidth: 50 },  // Adjust width for URL
                3: { cellWidth: 90 },  // Adjust width for request body
                4: { cellWidth: 50 },  // Adjust width for timestamp
            },
            styles: {
                fontSize: 8,
                overflow: 'linebreak',
                cellWidth: 'auto',   // Allow auto width adjustment
                minCellHeight: 10,   // Minimum height for readability
                halign: 'left',      // Align text to the left
            },
            margin: { top: 20, left: 15, right: 15 }, // Added left and right margins
            pageBreak: 'auto',
        });
    
        // Save the PDF
        doc.save(`${record.clientDetails.client_name || 'audit_summary'}_audit_summary.pdf`);
    };
    

    

    const toggleExpandedRow = (recordId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [recordId]: !prev[recordId],
        }));
    };

    useEffect(() => {
        fetchAuditSummaries();
    }, []);

    const columns = [
        {
            title: 'Client Name',
            dataIndex: 'clientDetails.client_name',
            render: (text, record) => record.clientDetails?.client_name || 'N/A',
        },
        {
            title: 'API ID',
            dataIndex: 'api_id',
        },
        {
            title: 'Hit Count',
            dataIndex: 'hit_count',
        },
        {
            title: 'Latest Hit',
            dataIndex: 'last_hit',
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Action',
            render: (text, record) => (
                <Space>
                    <Tooltip title="Export to PDF">
                        <FilePdfOutlined
                            style={{ fontSize: '24px', color: '#ff4d4f', cursor: 'pointer' }}
                            onClick={() => generatePDF(record)}
                        />
                    </Tooltip>
                    <Button onClick={() => toggleExpandedRow(record.id)}>
                        {expandedRows[record.id] ? 'Hide Details' : 'Show Details'}
                    </Button>
                </Space>
            ),
        },
    ];

    const expandedRowRender = (record) => {
        return (
            <div>
                <h4>Audit Logs</h4>
                {record.auditLogs.map((log, index) => (
                    <div key={index}>
                        <strong>Method:</strong> {log.method} <br />
                        <strong>Status Code:</strong> {log.status_code} <br />
                        <strong>URL:</strong> {log.url} <br />
                        <strong>Request Body:</strong> {log.request_body} <br />

{/*                        <Tooltip title={log.response_body}>
                            <span>
                                <strong>Response Body:</strong> {log.response_body.length > 100 ? `${log.response_body.substring(0, 100)}...` : log.response_body}
                            </span>
                        </Tooltip>
                        <br />
 */}                       
                        <strong>Timestamp:</strong> {new Date(log.timestamp).toLocaleString()} <br />
                        <hr />
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Card className="grid-card">
          
            <h2 style={{ textAlign: 'center', marginBottom: '10px', color: '#1890ff' }}>Audit Summary</h2>
            {loading ? (
                <div style={{ textAlign: 'center', margin: '20px 0' }}>
                    <Spin size="large" />
                </div>
            ) : error ? (
                <Alert message={error} type="error" showIcon style={{ marginBottom: '16px' }} />
            ) : (
                <>
                    <Search
                        placeholder="Search by Client Name"
                        onSearch={handleSearch}
                        style={{ width: 300, marginBottom: 16 }}
                    />
                    <Table
                        columns={columns}
                        dataSource={filteredData}
                        rowKey="id"
                        className="grid-table"
                        scroll={{ x: true }}
                        expandable={{
                            expandedRowRender: (record) => expandedRows[record.id] && expandedRowRender(record),
                        }}
                    />
                </>
            )}
        </Card>
    );
};

export default ClientAuditDetails;

// AdminConsole.js
import React, { useEffect, useState, useRef  } from 'react';
import axiosInstance from './config/axiosInstance'; // Use the configured Axios instance
import config from './config/config';
//import './assets/css/clientToken.css'


const ClientToken = () => {
  const [clients, setClients] = useState([]); // State to hold client data
  const [selectedClient, setSelectedClient] = useState(''); // State for the selected client
  const [method, setMethod] = useState('POST');
  const [url, setUrl] = useState('');
  const [body, setBody] = useState('');
  const [headers, setHeaders] = useState([{ key: '', value: '' }]);
  const [responseBody, setResponseBody] = useState('');
  const [responseHeaders, setResponseHeaders] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [curlCommand, setCurlCommand] = useState('');
  const [tooltipText, setTooltipText] = useState('');
  const tooltipRef = useRef(null);
  const [showNotification, setShowNotification] = useState(false);
  const [disableFields, setDisableFields] = useState(false); // New state to control disabling fields


 
    // Fetch clients from API on page load
    useEffect(() => {
      fetchClients();
      setDisableFields(true);
  }, []);

  // Fetch clients from the API
  const fetchClients = async () => {
      try {
          const response = await axiosInstance.get(`${config.API_CLIENT_URL}`); // Replace with your API endpoint
          setClients(response.data);
      } catch (error) {
          console.error('Error fetching clients:', error);
        
      }
  };

  const handleClientChange = (e) => {
    const clientId = e.target.value;
    setSelectedClient(clientId);
debugger;
    // Assuming clients is an array of objects with id, client_id, client_secret, scope, and grant_type
    const client = clients.find(c => c.id === Number(clientId));
    if (client) {
      setUrl(`${config.API_BASEURL}`+`${config.auth_token_url}`);
      setHeaders([
        { key: 'client_id', value: client.client_id },
        { key: 'client_secret', value: client.client_secret },
        { key: 'scope', value: `${config.scope}` },
        { key: 'grant_type', value: `${config.grant_type}` },
      ]);
      setDisableFields(true); // Disable headers, method, and URL
    }
    else {
      // If no client is selected, clear the fields
      setUrl('');
      setBody('');
      setHeaders([{ key: '', value: '' }]); // Reset headers to empty
      setDisableFields(false); // Enable the fields again if no client is selected
    }
  };

  const addHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  const removeHeader = (index) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  const handleHeaderChange = (index, type, value) => {
    const newHeaders = [...headers];
    newHeaders[index][type] = value;
    setHeaders(newHeaders);
  };

  const sendRequest = async () => {
    if (!url) {
     // alert('Please enter a valid API URL');
      return;
    }

    const headerObject = headers.reduce((obj, header) => {
      if (header.key && header.value) {
        obj[header.key] = header.value;
      }
      return obj;
    }, {});

    const options = {
      method,
      headers: {
        ...headerObject,
        'clientObjectId': selectedClient, // Add clientObjectId in the headers
      },
      ...(body && { body })
    };

    try {
      const response = await fetch(url, options);
      const responseBody = await response.text();

      const curl = generateCurlCommand(method, url, headerObject, body);

      setResponseBody(responseBody);
      setResponseHeaders(JSON.stringify([...response.headers], null, 2));
      setResponseStatus(`${response.status} ${response.statusText}`);
      setCurlCommand(curl);
    } catch (error) {
      setResponseBody('Error: ' + error);
      setResponseHeaders('');
      setResponseStatus('Error');
      setCurlCommand('');
    }
  };

  const generateCurlCommand = (method, url, headers, data) => {
    let curl = `curl -X ${method} '${url}'`;

    // Append headers to the curl command
    if (Object.keys(headers).length > 0) {
      Object.keys(headers).forEach(header => {
        curl += ` -H '${header}: ${headers[header]}'`;
      });
    }

    // Append body data for POST, PUT, etc.
    if (data && (method === 'POST' || method === 'PUT')) {
      curl += ` -d '${data}'`;
    }

    return curl;
  };

  const copyToClipboard = (elementId) => {
    const textArea = document.getElementById(elementId);
    textArea.select();
    document.execCommand('copy');
    // Display copied message (could be improved with a tooltip)
    
   // setTooltipText('Copied to clipboard!');
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 2000); // Hide after 2 seconds



    // Show tooltip
    if (tooltipRef.current) {
      const tooltipInstance = new window.bootstrap.Tooltip(tooltipRef.current); // Use window.bootstrap
      tooltipInstance.show();


      setTimeout(() => {
        tooltipInstance.hide();
      }, 2000); // Hide after 2 seconds
    }
  };

  return (
    <div className="container mt-5">
    <div className="card shadow-lg p-4">  
      
      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#1890ff' }}>Client Token Generation</h2>
      {showNotification && (
          <div className="alert alert-success" role="alert">
            Copied to clipboard!
          </div>
        )}
      <form id="api-form">
      <h5>Client</h5>  
      <div className="row mb-2"> 
        <div className="col-md-3">
              <select
                className="form-select form-select-sm"
                value={selectedClient}
                onChange={handleClientChange}
              >
                <option value="">Select Client</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.client_name} {/* Assuming client has a 'name' property */}
                  </option>
                ))}
              </select>
            </div></div>
            <h5>Method</h5>
            <div className="row mb-2">
          <div className="col-md-3">
            <select
              className="form-select form-select-sm"
              value={method}
              onChange={(e) => setMethod(e.target.value)
                
              }
              disabled={disableFields} // Disable the method field 
            >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
            </select>
          </div>
          <div className="col-md-5">
            <input
              type="text"
              className="form-control form-control-sm"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter API URL"
              disabled={disableFields} // Disable the method field
            />
          </div>
        </div>

        <h5>Headers</h5>
        <div id="headers-container">
          {headers.map((header, index) => (
            <div className="row mb-2 header-row" key={index}>
              <div className="col-md-3">
                <select
                  className="form-control form-control-sm"
                  value={header.key}
                  onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
                  disabled={disableFields} // Disable the method field
                >
                  <option value="">Select Header</option>
                  <option value="Content-Type">Content-Type</option>
                  <option value="Authorization">Authorization</option>
                  <option value="Accept">Accept</option>
                  <option value="User-Agent">User-Agent</option>
                  <option value="Cache-Control">Cache-Control</option>
                  <option value="client_id">client_id</option>
                  <option value="client_secret">client_secret</option>
                  <option value="grant_type">grant_type</option>
                  <option value="scope">scope</option>
                </select>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={header.value}
                  onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                  placeholder="Enter header value"
                  disabled={disableFields} // Disable the method field
                />
              </div>
            {/*  <div className="col-md-2">
                <span
                  className="remove-header"
                  onClick={() => removeHeader(index)}
                >
                  🗑️
                </span>
              </div>
              */}
            </div>
          ))}
        </div>
        {!disableFields && (
        <button type="button" className="btn btn-secondary btn-sm mb-2" onClick={addHeader}>
          <span className="add-header">➕</span>Add Header
        </button>
 )}
        <h5>Body</h5>
        <div className="form-group col-md-6">
          <textarea
            className="form-control form-control-sm"
            rows="3"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            placeholder=""
            disabled={disableFields} // Disable the body input
          ></textarea>
        </div>

        <button type="button" className="btn btn-primary btn-sm request-btn" onClick={sendRequest}
         disabled={!selectedClient} // Only enable send request if client is selected
        >
          Generate Token
        </button>
      </form>

      <h5 className="mt-4">Response</h5>
      <ul className="nav nav-tabs" id="responseTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="body-tab" data-bs-toggle="tab" data-bs-target="#body" type="button" role="tab" aria-controls="body" aria-selected="true">Body</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="headers-tab" data-bs-toggle="tab" data-bs-target="#headers" type="button" role="tab" aria-controls="headers" aria-selected="false">Headers</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="status-tab" data-bs-toggle="tab" data-bs-target="#status" type="button" role="tab" aria-controls="status" aria-selected="false">Status</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="curl-tab" data-bs-toggle="tab" data-bs-target="#curl" type="button" role="tab" aria-controls="curl" aria-selected="false">cURL</button>
        </li>
      </ul>
      <div className="tab-content" id="responseTabContent">
        <div className="tab-pane fade show active" id="body" role="tabpanel" aria-labelledby="body-tab">
          <button className="copy-btn" onClick={() => copyToClipboard('response-body')}>Copy</button>
          <div className="d-flex justify-content-between">
            <textarea className="form-control form-control-sm mt-3" id="response-body" rows="5" readOnly value={responseBody} placeholder="Response body will appear here"></textarea>
          </div>
        </div>
        <div className="tab-pane fade" id="headers" role="tabpanel" aria-labelledby="headers-tab">
          <button className="copy-btn" onClick={() => copyToClipboard('response-headers')}>Copy</button>
          <div className="d-flex justify-content-between">
            <textarea className="form-control form-control-sm mt-3" id="response-headers" rows="5" readOnly value={responseHeaders} placeholder="Response headers will appear here"></textarea>
          </div>
        </div>
        <div className="tab-pane fade" id="status" role="tabpanel" aria-labelledby="status-tab">
          <div className="d-flex justify-content-between">
            <button className="copy-btn" onClick={() => copyToClipboard('response-status')}>Copy</button>
            <input className="form-control form-control-sm mt-3" id="response-status" type="text" readOnly value={responseStatus} placeholder="Response status will appear here" />
          </div>
        </div>
        <div className="tab-pane fade" id="curl" role="tabpanel" aria-labelledby="curl-tab">
          <button className="copy-btn" onClick={() => copyToClipboard('curl-command')}>Copy</button>
          <div className="d-flex justify-content-between">
            <textarea className="form-control form-control-sm mt-3" id="curl-command" rows="5" readOnly value={curlCommand} placeholder="cURL command will appear here"></textarea>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ClientToken;
